import _helpers2 from "./helpers";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _helpers = _helpers2;

var normalizeOptions = function normalizeOptions(opts) {
  return {
    delimiter: opts.delimiter || "&",
    encoder: opts.encoder || encodeURIComponent,
    encodeKeys: !!opts.encodeKeys
  };
};

var stringify = function stringify(key, value, options) {
  if ((0, _helpers.isArray)(value)) {
    var arrKey = "".concat(key, "[]");
    if (value.length === 0) return stringify(arrKey, null, options);
    return value.map(function (child) {
      return stringify(arrKey, child, options);
    }).join(options.delimiter);
  }

  if ((0, _helpers.isPlainObject)(value)) {
    var _parts = [];
    Object.keys(value).sort().forEach(function (childKey) {
      _parts.push(stringify("".concat(key, "[").concat(childKey, "]"), value[childKey], options));
    });
    return _parts.join(options.delimiter);
  }

  if ("undefined" === typeof value) return "";
  var parts = [options.encodeKeys ? options.encoder(key) : key];
  if (value !== null) parts.push(options.encoder(value));
  return parts.join("=");
};

var _default = function _default(obj) {
  var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var options = normalizeOptions(opts);
  var parts = [];

  for (var key in obj) {
    parts.push(stringify(key, obj[key], options));
  }

  return parts.filter(function (p) {
    return p.length > 0;
  }).join(options.delimiter);
};

exports.default = _default;
export default exports;