import _helpers2 from "./helpers";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _helpers = _helpers2;

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var normalizeOptions = function normalizeOptions(opts) {
  return {
    delimiter: opts.delimiter || "&",
    decoder: opts.decoder || decodeURIComponent,
    depth: opts.depth || 5,
    parseBooleans: !!opts.parseBooleans
  };
};

var assertArray = function assertArray(key, obj) {
  if (!(0, _helpers.isArray)(obj)) {
    throw new Error("Expected array (got ".concat(obj.constructor.name, ") for param ").concat(key));
  }
};

var assertPlainObject = function assertPlainObject(key, obj) {
  if (!(0, _helpers.isPlainObject)(obj)) {
    throw new Error("Expected object (got ".concat(obj.constructor.name, ") for param ").concat(key));
  }
};

var parseRawValue = function parseRawValue(value, options) {
  if (typeof value === "undefined") {
    return null;
  }

  if (options.parseBooleans && value !== null && ["true", "false"].includes(value.toString().toLowerCase())) {
    return value.toLowerCase() === "true";
  }

  return value;
};

var normalizeParams = function normalizeParams(params, key, value, depth, options) {
  if (depth <= 0) {
    throw new RangeError("Exceeded maximum depth");
  }

  var keyMatch = key.match(/^[\[\]]*([^\[\]]+)\]*/) || [];
  var k = keyMatch[1] || "";
  var after = key.substr((keyMatch[0] || "").length);

  if (k.length === 0) {
    if (value !== null && key == "[]") {
      return (0, _helpers.isArray)(value) ? value : [value];
    } else {
      return;
    }
  }

  if (after === "") {
    params[k] = parseRawValue(value, options);
  } else if (after == "[]") {
    params[k] = params[k] || [];
    assertArray(k, params[k]);
    if (typeof value !== "undefined") params[k].push(parseRawValue(value, options));
  } else {
    var afterMatch = after.match(/^\[\]\[([^\[\]]+)\]$/) || after.match(/^\[\](.+)$/);

    if (afterMatch) {
      params[k] = params[k] || [];
      assertArray(k, params[k]);
      var last = params[k][params[k].length - 1];

      if ((0, _helpers.isPlainObject)(last) && !(0, _helpers.objectHas)(last, afterMatch[1])) {
        normalizeParams(last, afterMatch[1], value, depth - 1, options);
      } else {
        params[k].push(normalizeParams({}, afterMatch[1], value, depth - 1, options));
      }
    } else {
      params[k] = params[k] || {};
      assertPlainObject(k, params[k]);
      normalizeParams(params[k], after, value, depth - 1, options);
    }
  }

  return params;
};

var _default = function _default(str) {
  var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var params = {};
  var options = normalizeOptions(opts);
  var qs = str || "";
  qs.split(options.delimiter).forEach(function (part) {
    var _part$split$map = part.split("=").map(function (p) {
      return options.decoder(p);
    }),
        _part$split$map2 = _slicedToArray(_part$split$map, 2),
        key = _part$split$map2[0],
        value = _part$split$map2[1];

    normalizeParams(params, key, value, options.depth, options);
  });
  return params;
};

exports.default = _default;
export default exports;