import _parse2 from "./parse";
import _stringify2 from "./stringify";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "parse", {
  enumerable: true,
  get: function get() {
    return _parse.default;
  }
});
Object.defineProperty(exports, "stringify", {
  enumerable: true,
  get: function get() {
    return _stringify.default;
  }
});

var _parse = _interopRequireDefault(_parse2);

var _stringify = _interopRequireDefault(_stringify2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

export default exports;
export const __esModule = exports.__esModule,
      parse = exports.parse,
      stringify = exports.stringify;