var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.objectHas = exports.isArray = exports.isPlainObject = void 0;

function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

var isPlainObject = function isPlainObject(obj) {
  return obj !== null && _typeof(obj) === "object" && obj.constructor === Object;
};

exports.isPlainObject = isPlainObject;

var isArray = function isArray(obj) {
  return Array.isArray(obj);
};

exports.isArray = isArray;

var objectHas = function objectHas(obj, key) {
  return obj.hasOwnProperty(key);
};

exports.objectHas = objectHas;
export default exports;